@import url("https://fonts.googleapis.com/css2?family=Quicksand:wght@300..700&display=swap");

/* .navbar {
  padding-right: 20%;
} */

.logo {
  width: 173px;
  height: 125px;
  transition: opacity 0.3s ease;
  /* background-blend-mode: multiply; */
  background-color: #eef4ff;
}

.hidden {
  opacity: 0;
  width: 0;
  height: 0;
  overflow: hidden;
}

.companylogo {
  transition: opacity 0.3s ease, width 0.3s ease, height 0.3s ease;
}

nav {
  width: 100%;
  height: 123px;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 200;
  background-color: #eef4ff;
  transition: height 0.3s ease, background-color 0.3s ease;
  display: flex;
  justify-content: center;
  gap: 200px;
}

.navbar-shrink {
  height: 80px;
  justify-content: center;
  gap: 0px;
}

nav ul li {
  display: inline-block;
  list-style: none;
  font-size: 15px;
  font-family: "Quicksand", sans-serif;
  font-weight: 650;
  transition: 0.3s;
  letter-spacing: 1px;
  margin: 10px;
}

.navbarlinks {
  text-decoration: none;
  color: rgb(3, 3, 126);
  list-style: none;
}

.menuicon {
  display: none;
}

/* NEWWWW */
@media (max-width: 1345px) {
  nav ul li {
    font-size: 15px;
    font-family: "Quicksand", sans-serif;
    font-weight: bolder;
  }
  .logo {
    width: 180px;
    height: 100px;
    transition: opacity 0.3s ease;
  }
}

@media (max-width: 1115px) {
  nav ul li {
    font-size: 12px;
    font-family: "Quicksand", sans-serif;
    font-weight: bolder;
    margin: 5px;
  }
}

@media (max-width: 1011px) {
  nav ul li {
    font-size: 11px;
    font-family: "Quicksand", sans-serif;
    font-weight: bolder;
    margin: 5px;
  }
  .logo {
    width: 130px;
    height: 70px;
    transition: opacity 0.3s ease;
  }
}

@media (max-width: 926px) {
  nav {
    justify-content: space-around;
  }
  nav ul {
    position: fixed;
    top: 0;
    right: 0px;
    bottom: -60px;
    background-color: #eef4ff;
    z-index: -100;
    width: 220px;
    transition: 0.5s;
    display: block;
    padding-top: 100px;
    text-align: start;
    overflow: hidden;
  }

  .navbar ul li {
    margin: 20px 35px;
    font-size: 15px;
    font-weight: bold;
  }

  .menuicon {
    display: block;
    width: 30px;
    cursor: pointer;
    padding-right: 20px;
  }

  .hide-mobile-menu {
    right: -260px;
  }

  .hidden {
    opacity: 1;
    overflow: visible;
    align-items: center;
    justify-content: space-between;
    text-align: center;
    display: flex;
  }

  .navbar-shrink {
    height: 120px;
    justify-content: space-between;
  }

  .companylogo {
    transition: none;
  }

  .logo {
    transition: none;
    padding-left: 5px;
  }
}

@media (max-width: 926px) {
  nav {
    justify-content: space-between;
  }
}
