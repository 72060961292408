@import url("https://fonts.googleapis.com/css2?family=Quicksand:wght@300..700&display=swap");

/* HOME */

.homebanner {
  display: flex;
  height: 70vh;
  margin-left: 12.5%;
  margin-right: 12.5%;
  text-align: center;
  align-items: center;
  justify-content: center;
  border: 2px whitesmoke solid;
}

.homebanner img {
  width: 100%;
  height: 70vh;
  object-fit: cover;
  opacity: 0.5;
  border: 2px whitesmoke solid;
}

.homebannertext {
  position: absolute;
  max-width: fit-content;
  margin-left: auto;
  margin-right: auto;
  z-index: 1;
  color: black;
  text-transform: uppercase;
  font-size: 27px;
  text-align: left;
  font-weight: 400;
  text-align: center;
}

.homebannertext h4 {
  color: rgb(3, 146, 3);
}

.homebannertext h1 {
  color: rgb(3, 3, 126);
}

.bannerbutton,
.calculatorbutton {
  background-color: rgb(3, 3, 126);
  color: white;
  border: none;
  padding: 15px;
  cursor: pointer;
  font-weight: 550;
  font-style: normal;
}

.bannerbutton:hover,
.calculatorbutton:hover {
  background-color: purple;
  color: white;
  transform: ease-in-out;
  transition: 0.6s;
  font-weight: 550;
  font-style: normal;
}

.homedescription {
  display: grid;
  grid-template-columns: 50% 50%;
  text-align: justify;
  gap: 30px;
  margin-left: 12.5%;
  margin-right: 12.5%;
  padding-top: 50px;
  padding-bottom: 50px;
  line-height: 26px;
}

.homecaption {
  padding-top: 40px;
  color: rgb(3, 146, 3);
}

.aboutus,
.process,
.loan {
  padding: 2rem;
  border: 2px whitesmoke solid;
}

.aboutusdescription,
.processdescription {
  padding-bottom: 20px;
  align-items: start;
  text-align: left;
  line-height: 25px;
}

.aboutustitle,
.processtitle,
.loantitle {
  height: auto;
  line-height: 25px;
  font-size: 28px;
  font-weight: bolder;
  letter-spacing: 0.5px;
  color: purple;
  align-items: start;
  text-align: left;
}

.readmorebutton {
  cursor: pointer;
  font-family: "Quicksand", sans-serif;
  font-weight: 600;
  border: none;
  background-color: transparent;
}

/* HOME ANIMINATION */
@keyframes slideIn {
  0% {
    transform: translateX(-100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

.homebannertext {
  animation: slideIn 1s ease-in;
}

/* CONTACT */

.contactformarea {
  padding: 2rem;
  border: 2px whitesmoke solid;
  margin-bottom: 70px;
  margin-left: 20%;
  margin-right: 20%;
  margin-top: 50px;
}

.contactform {
  display: flex;
  gap: 100px;
  justify-content: center;
}

.contactformtitle {
  padding-bottom: 50px;
  text-transform: uppercase;
  font-weight: bolder;
  color: rgb(3, 3, 126);
  letter-spacing: 1px;
}

.contactformright {
  display: flex;
  flex-direction: column;
  gap: 30px;
  font-size: 18px;
}

.phone,
.email,
.address {
  display: flex;
  text-align: left;
  gap: 15px;
}

.email a,
.address a {
  text-decoration: none;
  color: black;
}

.phone img,
.address img,
.email img {
  width: 20px;
  object-fit: contain;
}

.socialtitle {
  font-weight: bolder;
}

.contactsocial {
  justify-content: left;
  display: flex;
  flex-direction: column;
  text-align: left;
}

.sociallogos {
  justify-content: left;
  align-items: center;
  display: flex;
  gap: 20px;
}

.sociallogos img {
  width: 30px;
  object-fit: contain;
}

.contactsocials img:hover {
  cursor: pointer;
  transform: scale(1.5);
}

a[href^="tel"] {
  color: inherit;
  text-decoration: none;
  font-size: 16px;
  font-weight: bolder;
}

/* SMSF */

/* calculator */

.calculatorcontext {
  text-align: left;
  display: flex;
  flex-direction: column;
  border: 1px whitesmoke solid;
  border-radius: 20px;
  margin-left: 20%;
  margin-right: 18%;
  padding: 30px;
  line-height: 26px;
}

.calculators {
  display: grid;
  grid-template-columns: 50% 50%;
  text-align: justify;
  gap: 30px;
  margin-left: 20%;
  margin-right: 20%;
  padding-top: 10px;
  padding-bottom: 100px;
  line-height: 26px;
}

.calculatorbutton a {
  text-decoration: none;
  color: white;
}

/* LOANS */

.loansdescription,
.firsthomedescription {
  display: grid;
  grid-template-columns: 50% 50%;
  text-align: left;
  gap: 30px;
  margin-left: 20%;
  margin-right: 20%;
  padding-top: 10px;
  padding-bottom: 100px;
  line-height: 26px;
}

.loanssection,
.firsthomesection {
  padding: 1.5rem;
  border-radius: 10px;
  border: 2px whitesmoke solid;
  text-align: left;
}

.loanstitle,
.firsthometitle {
  height: auto;
  line-height: 40px;
  font-size: 25px;
  font-weight: 600;
  color: purple;
}

.loanssection span,
.firsthomesection span {
  font-weight: 550;
  font-size: 18px;
}

/* MULTIPLE USES */

.mortgage,
.loancomparision,
.interestonly,
.carloan,
.stampduty,
.borrowing,
.mortgageswitching,
.loanrepayment,
.interestonlymortgage {
  padding: 1.5rem;
  border-radius: 10px;
  border: 2px whitesmoke solid;
  text-align: left;
}

.contactbanner,
.smsfheader,
.claculatorheader,
.termsandconditionheader,
.privacypolicyheader,
.loansheader,
.firsthomeheader,
.faqheader {
  display: flex;
  height: 200px;
  align-items: center;
  justify-content: center;
}

.contacttitle,
.smsftitle,
.calculatortitle,
.termsandconditiontitle,
.privacypolicytitle,
.loansbannertitle,
.firsthomebannertitle,
.faqtitle {
  position: absolute;
  font-size: 35px;
  font-weight: bolder;
  color: rgb(3, 146, 3);
  margin-left: 20%;
  margin-right: 20%;
}

.contactbanner img,
.claculatorheader img,
.firsthomeheader img,
.loansheader img,
.smsfheader img {
  opacity: 0.5;
  height: 200px;
  width: 100%;
  object-fit: cover;
}

.smsfcontext,
.termsandconditioncontext,
.privacypolicycontext,
.termsandconditioncontext,
.faqcontext {
  text-align: start;
  display: grid;
  margin-left: 20%;
  margin-right: 20%;
  border: 1px whitesmoke solid;
  border-radius: 20px;
  padding: 20px;
  margin-bottom: 50px;
  line-height: 26px;
}

.aboutusdescription span,
.processdescription span,
.loandescription span,
.smsfcontext span,
.calculatorcontext span,
.calculatorconclusion span,
.termsandconditioncontext span,
.privacypolicycontext span,
.faqcontext span {
  font-weight: 550;
  font-size: 18px;
}

/* Responsive */

/* HOME */

@media (max-width: 629px) {
  .homebannertext {
    font-size: 25px;
  }
}

@media (max-width: 592px) {
  .homebannertext {
    left: 0;
    font-size: 25px;
    padding: 15px;
    text-align: start;
  }
}

@media (max-width: 923px) {
  .homedescription {
    display: inline-block;
  }

  .homebanner {
    margin: 0%;
  }
}

@media (max-width: 500px) {
  .homebanner {
    height: 80vh;
    margin: 0%;
  }
  .homebannertext {
    left: 0;
    font-size: 25px;
    padding: 15px;
    text-align: start;
  }

  .homedescription {
    display: inline-block;
    margin: 0;
    text-align: start;
  }
}

/* LOANS */
@media (max-width: 897px) {
  .loansdescription,
  .firsthomedescription {
    display: inline-block;
    margin: 0;
  }
}

/* CONTACT */

@media (max-width: 1410px) {
  .email a {
    font-size: 16px;
  }
}

@media (max-width: 1346px) {
  .contactform {
    display: flex;
    flex-direction: column;
    /* overflow: hidden; */
    margin: 0;
    justify-content: center;
    align-items: center;
  }
}
@media (max-width: 500px) {
  .contactform {
    justify-content: left;
    align-items: start;
    text-align: left;
    display: flex;
    padding-top: 10px;
  }
  .contactformarea {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    margin: 0px;
    padding: 30px;
    margin-top: 50px;
    margin-bottom: 50px;
    justify-content: left;
    align-items: start;
  }

  .contactformtitle {
    text-align: left;
    font-size: 15px;
  }
}

/* CALCULATOR */
@media (max-width: 897px) {
  .calculators {
    display: inline-block;
  }
}

/* MULTIPLE */

@media (max-width: 982px) {
  .smsfheader,
  .claculatorheader,
  .termsandconditionheader,
  .privacypolicyheader,
  .loansheader,
  .faqheader,
  .firsthomeheader {
    display: flex;
    text-align: center;
    justify-content: center;
    padding: 1.5rem;
    margin: 0;
    height: 150px;
  }
}

@media (max-width: 685px) {
  .calculatorcontext {
    margin: 0;
  }
  .calculators {
    margin: 0;
  }

  .smsfcontext,
  .termsandconditioncontext,
  .privacypolicycontext,
  .termsandconditioncontext,
  .faqcontext {
    margin: 0;
    margin-bottom: 30px;
  }
  .contactbanner,
  .smsfheader,
  .claculatorheader,
  .termsandconditionheader,
  .privacypolicyheader,
  .loansheader,
  .firsthomeheader,
  .faqheader,
  .firsthomeheader {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    margin: 0;
    height: 170px;
  }
  .contacttitle,
  .smsftitle,
  .calculatortitle,
  .termsandconditiontitle,
  .privacypolicytitle,
  .firsthomebannertitle {
    font-size: 30px;
    padding-bottom: 10px;
  }
  .contacttitle span,
  .smsftitle span,
  .calculatortitle span,
  .termsandconditiontitle span,
  .privacypolicytitle span {
    font-size: 18px;
  }
}
