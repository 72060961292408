@import url("https://fonts.googleapis.com/css2?family=Quicksand:wght@300..700&display=swap");

.footer {
  background-color: #eef4ff;
  height: auto;
  color: white;
  font-family: "Quicksand", sans-serif;
  padding-top: 20px;
  padding-bottom: 20px;
}

.footertop {
  display: flex;
  justify-content: center;
  align-items: start;
  gap: 100px;
  padding-bottom: 10px;
  color: rgb(3, 3, 126);
}

.footerlogo {
  display: flex;
  flex-direction: column;
  gap: 5px;
  padding-bottom: 10px;
  font-size: 9.6px;
  font-weight: bolder;
  text-align: left;
}

.logos {
  display: flex;
  align-items: end;
}

.footerworkhour {
  text-align: start;
  font-weight: bolder;
}

.footerlinks {
  display: flex;
  flex-direction: column;
  font-weight: bolder;
  gap: 20px;
  align-items: start;
  margin-top: 30px;
  text-align: start;
}

.footerlinks:hover {
  cursor: pointer;
}

.footer hr {
  color: rgb(3, 3, 126);
  opacity: 20%;
  width: 80%;
  margin: auto;
}

.socials {
  justify-content: flex-start;
  align-items: center;
  text-align: start;
  display: flex;
  gap: 15px;
  margin-left: -10px;
  margin-top: -17px;
}

#facebook,
#instagram,
#youtube {
  width: 30px;
  object-fit: contain;
}

#facebook:hover,
#instagram:hover,
#youtube:hover {
  cursor: pointer;
  transform: scale(1.5);
}

.footerbottom {
  display: flex;
  justify-content: space-around;
  padding-top: 10px;
  color: rgb(3, 3, 126);
  font-weight: 500;
  font-size: 14px;
}

/* RESPONSIVE */

@media (max-width: 1003px) {
  .footertop {
    display: flex;
    flex-direction: column;
    text-align: start;
    align-items: start;
    gap: 0;
    /* padding-left: 40px; */
  }
}

@media (max-width: 800px) {
  .footerlogo {
    padding-bottom: 20px;
    padding-left: 40px;
  }

  .footertop {
    display: flex;
    flex-direction: column;
    text-align: start;
    align-items: start;
    gap: 0;
  }

  .footerlinks {
    padding-left: 40px;
    margin-top: 20px;
  }

  #workhour {
    margin-top: 5px;
    padding-bottom: 5px;
  }

  .footer hr {
    width: 85%;
    color: rgb(3, 3, 126);
  }
  .footerbottom {
    justify-content: center;
    padding-left: 40px;
    padding-right: 40px;
  }
}
