@import url("https://fonts.googleapis.com/css2?family=Quicksand:wght@300..700&display=swap");

form {
  display: flex;
  flex-direction: column;
}

input[type="text"],
input[type="email"],
input[type="tel"],
textarea {
  border: none;
  outline: none;
  border-bottom: 2px solid black;
}

textarea::placeholder {
  padding-top: 83px;
}

input {
  outline: none;
  font-family: "Quicksand", sans-serif;
  letter-spacing: 0.5px;
  width: 400px;
  padding-top: 25px;
}

textarea {
  outline: none;
  font-family: "Quicksand", sans-serif;
  letter-spacing: 0.5px;
  width: 400px;
  height: 100px;
}

textarea::placeholder {
  padding-top: 83px;
}

::placeholder {
  color: rgb(3, 3, 126);
  font-family: "Quicksand", sans-serif;
  font-weight: 550;
  letter-spacing: 1px;
}

.formbutton {
  border: none;
  width: 400px;
  padding: 10px;
  background-color: white;
  color: rgb(3, 3, 126);
  cursor: pointer;
  letter-spacing: 1px;
  font-size: 15px;
  text-transform: uppercase;
  font-weight: bolder;
}

.formbutton:hover {
  color: purple;
  transform: ease-in-out;
  transition: 0.6s;
}

@media (max-width: 500px) {
  input,
  textarea {
    width: 320px;
    text-align: left;
    align-items: start;
    justify-content: left;
  }
  .formbutton {
    width: 320px;
  }
}
