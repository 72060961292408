.caption {
  letter-spacing: 3px;
  font-weight: 700;
  font-family: "Quicksand", sans-serif;
  font-style: normal;
  font-size: 25px;
  opacity: 0; /* Start hidden */
  transform: translateY(100%); /* Start from below */
  transition: transform 0.5s ease-in, opacity 0.5s ease-in; /* Set up transition */
}

.caption.visible {
  opacity: 1; /* Become visible */
  transform: translateY(0); /* Move to normal position */
}
